<template>
  <b-card>
    <div class="list-view mb-1">
      <b-row class="m-1 top-header">
        <b-col md="6" class="d-flex align-items-center cursor-pointer">
          <div @click="goBack">
            <feather-icon icon="ArrowLeftIcon" size="24" />
          </div>
          <h3 class="ml-2 mb-0">{{ this.hatch_id }}</h3>
        </b-col>
      </b-row>
      <b-row class="justify-content-center m-2">
        <div class="d-flex">
          <div class="d-flex" style="position: relative">
            <b-card class="ml-2 pt-2" style="background-color: #d7f3e1">
              <div class="d-flex">
                <b-img
                  :src="require('@/assets/images/timeline/product.svg')"
                  style="height: 40px; width: 40px"
                />
                <div class="ml-1">
                  <p style="margin-bottom: 10px">
                    Purchased From &nbsp;
                    <!-- {{ this.dayInfo.source ? this.dayInfo.source : "NA" }} -->
                    {{ dayInfo.source ? dayInfo.source.name : "NA" }}
                    <!-- <span class="info-text">SET5024, HAT1121</span> -->
                  </p>
                  <p style="margin-bottom: 10px">
                    <b-img
                      :src="require('@/assets/images/timeline/date.svg')"
                      style="height: 15px; width: 15px; margin-right: 10px"
                    />
                    Date
                    <span class="info-text">{{
                      dayInfo.purchase_date ? dayInfo.purchase_date : "NA"
                    }}</span>
                  </p>
                  <div class="d-flex">
                    <div class="" style="margin-bottom: 10px">
                      <span class="mr-1"
                        ><b-img
                          :src="
                            require('@/assets/images/timeline/location.svg')
                          "
                          style="height: 15px; width: 15px; margin-right: 10px"
                          class=""
                        />{{
                          this.dayInfo.address ? this.dayInfo.address : "NA"
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex" style="margin-bottom: 10px">
                    <div class="mr-4">
                      <span class="mr-1">
                        <b-img
                          :src="require('@/assets/images/timeline/product.svg')"
                          style="height: 20px; width: 20px"
                        />
                      </span>
                      <span class=""
                        >Egg Qty &nbsp; {{ dayInfo.egg_qty || "NA" }}</span
                      >
                    </div>
                    <div class="mr-4">
                      <span class="mr-1">
                        <b-img
                          :src="require('@/assets/images/timeline/product.svg')"
                          style="height: 20px; width: 20px"
                        />
                      </span>
                      <span class="">
                        Damaged &nbsp; {{ dayInfo.damaged }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
            <div class="arrow"></div>
          </div>
          <b-card class="ml-2">
            <div class="d-flex">
              <b-img
                :src="require('@/assets/images/timeline/product.svg')"
                style="height: 40px; width: 40px"
              />
              <div class="ml-1">
                <p style="margin-bottom: 10px">
                  Set up and run
                  <!-- <span class="info-text">{{
                    dayTimeline.setter_machine_name
                  }}</span> -->
                </p>
                <p style="margin-bottom: 10px">
                  <b-img
                    :src="require('@/assets/images/timeline/date.svg')"
                    style="height: 15px; width: 15px; margin-right: 8px"
                  />
                  Date
                  <span class="info-text">{{ startDate[0] }}</span>
                </p>
                <div class="d-flex">
                  <div class="mr-4">
                    <span class="mr-1"> Temperature </span>
                    <span class="mr-1">
                      <b-img
                        :src="
                          require('@/assets/images/svg/machine/temprature.svg')
                        "
                      />
                    </span>
                    <span class="head-text"
                      >10
                      <sup
                        style="
                          color: #f6667e;
                          font-size: 10px;
                          margin-right: 3px;
                        "
                        >0</sup
                      ><span class="red-text">c</span></span
                    >
                  </div>
                  <div>
                    <span class="mr-1">
                      <b-img
                        :src="
                          require('@/assets/images/svg/machine/humidity.svg')
                        "
                      />
                    </span>
                    <span class="info-text"
                      >20

                      <span style="font-size: 12px">%</span></span
                    >
                  </div>
                </div>
                <div class="d-flex" style="margin-bottom: 10px">
                  <div class="mr-4">
                    <span class="mr-1"> Average Weight </span>

                    <span class="info-text">
                      {{ dayTimeline.avg_weight }}gram
                    </span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="success">
                    Flock ID : &nbsp;{{ dayInfo.flock_id }}
                  </div>
                  <div class="info">
                    <span> Batch ID : &nbsp;{{ dayInfo.batch_id }}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </b-row>
      <b-row class="p-1">
        <div
          class="col-6 d-flex mb-2 p-0"
          style="position: relative"
          v-for="(status, index) in stage_status"
          :key="index"
        >
          <b-card class="ml-2">
            <div class="d-flex">
              <b-img :src="{ getImage }" class="svg-img" />

              <div class="ml-1">
                <div>Day - {{ status.day_count }}</div>
                <p style="margin-bottom: 10px">{{ status.msg }}</p>
                <div class="d-flex">
                  <div class="mr-5">
                    <span class="mr-1">
                      <b-img
                        :src="
                          require('@/assets/images/svg/machine/temprature.svg')
                        "
                      />
                    </span>
                    <span class="head-text"
                      >10
                      <sup
                        style="
                          color: #f6667e;
                          font-size: 10px;
                          margin-right: 3px;
                        "
                        >0</sup
                      ><span class="red-text">c</span></span
                    >
                  </div>
                  <div>
                    <span class="mr-1">
                      <!-- <b-img :src="machineImage.image" /> -->
                    </span>
                    <span class="info-text">
                      <span class="mr-1"
                        ><b-img
                          :src="
                            require('@/assets/images/svg/machine/humidity.svg')
                          " /></span
                      >20

                      <span style="font-size: 12px">%</span></span
                    >
                  </div>
                </div>
                <div class="d-flex" style="margin-bottom: 10px">
                  <div class="mr-4 mt-1">
                    <span class="mr-1"> Average Weight </span>

                    <span class="info-text"> 0.003gm </span>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
          <div class="arrow"></div>
        </div>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
  BRow,
  BCol,
  BImg,
} from "bootstrap-vue";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BImg,
  },
  data() {
    return {
      dataTimeline: {},
      hatch_id: null,
      hatchery_device_id: null,
      startDate: null,
      machine_id: null,
      hatchery_id: null,
      stage_status: [],
      images: [require("./images/day-1.png"), require("./images/day-4.png")],
      dayInfo: {
        source: null,
        address: null,
        egg_qty: null,
        damaged: null,
        flock_id: null,
        batch_id: null,
        purchase_date: null,
      },
    };
  },
  created() {
    const hatch_id = this.$route.params.hatchId;
    this.hatchery_device_id = this.$route.params.hatchery_device_id;
    this.hatchery_id = this.$route.params.farmId;
    this.machine_id = this.$route.params.machine_id;
    this.hatch_id = hatch_id;
    this.getTimeLine();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getTimeLine() {
      axiosIns
        .get(
          `web/hatcheries/${this.hatchery_id}/Machine/${this.machine_id}/hatcher-hatch/timeline?hatch_id=${this.hatch_id}`
        )
        .then((response) => {
          this.dayTimeline = response.data.day1;
          //   this.dayInfo.source = response.data.day1.sourceInfo;
          this.dayInfo.address = response.data.day1.sourceInfo.address;
          this.dayInfo.egg_qty = response.data.day1.purchaseInfo.egg_qty;
          this.dayInfo.damaged = response.data.day1.purchaseInfo.damaged;
          this.dayInfo.flock_id = response.data.day1.purchaseInfo.flock_id;
          this.dayInfo.batch_id = response.data.day1.purchaseInfo.batch_id;
          this.dayInfo.purchase_date =
            response.data.day1.purchaseInfo.purchase_date;
          this.startDate = response.data.datePeriod;
          this.data = response.data;
          this.stage_status = response.data.stage_status;
        });
    },
    getImage(index) {
      return require("./images/day-4.png");
      if (index < 1) {
        return this.images[0];
      } else {
        return this.image[1];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-view {
  background-color: #fafafa;
  box-shadow: 0px 3px 6px #38c06c33;
  height: 100%;
  padding: 1rem 1rem;
  margin: auto;
}
.top-header {
  border-bottom: 1px solid #296db4;
  padding: 1rem 0rem;
}
.svg-img {
  height: 100px;
  width: 100px;
}
.timeline-card {
  width: 350px;
  margin-bottom: 2rem;
}
.arrow {
  margin-top: 15%;
  margin-left: 15px;
  border: 1px solid #00b866;
  background-color: #00b866;
  height: 1px;
  width: 120px;
}
.info-text {
  color: #00b866;
  font-size: 12px;
}
.success {
  background-color: #d7f3e1;
  font-size: 11px;
  color: rgba(41, 109, 180, 1);
  display: flex;
  align-items: center;
  margin-right: 1rem;
  width: 150px;
  padding: 15px 15px;
  height: 33px;
  border-radius: 16px;
  justify-content: center;
}
.info {
  background-color: #5dadfd33;
  font-size: 11px;
  color: #5dadfd;
  display: flex;
  align-items: center;
  width: 150px;
  padding: 1rem 1rem;
  height: 33px;
  justify-content: center;
  border-radius: 16px;
}
</style>
